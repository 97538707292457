<template>


  <div class="storefront">

    <div class="banner">
      <div class="sc-width banner-content">
        <div class="banner-left">
          <div class="title">
            Admire the Array of <span class="color">Global
              Raw Materials</span> Companies
          </div>
          <div class="sub-title">
            Finding Value and Trust in Every Company
          </div>
          <div class="banner-btn" @click="createStorefront()">
            Create Storefront
          </div>
        </div>
        <div class="banner-right">
          <video autoplay loop muted>
            <source src="https://connexima.s3-us-west-1.amazonaws.com/documents/jeqj2B8pDwJJoYNxnAoaSa.mp4">
          </video>
          <div class="hide-video"></div>
        </div>
      </div>


    </div>
    <div class="search sc-width">
      <div class="left-search">
        <div class="name">
          <span class="label">Name:</span>
          <input type="text" placeholder="Company name.."/>
        </div>
        <div class="industry">
          <span class="label">Industry:</span>
          <select >
            <option value="">All</option>
            <option value="">Food</option>
            <option value="">Pizza</option>
          </select>
        </div>

        <div class="btn">
          Filter
        </div>
      </div>
      <div class="right-search">

      </div>




    </div>
    <div class="companies">

      <div class="companies-content sc-width">

        <div class="item animatedBorder" @click="companyDetail()">
          <div class="item-wrap">
            <div class="item-mid">
            <div class="item-logo">
              <img src="@/assets/new-company/company-logo.png" alt="" />
            </div>
            <div class="item-right">
              <div class="item-name">DSM COMPANY
              </div>
              <div class="item-location">CA USA</div>
            </div>

          </div>
          <div class="item-bottom">
            <div class="bottom-item">
              <div class="item-value">100-200</div>
              <div class="item-label">Size</div>

            </div>
            <div class="bottom-line"></div>
            <div class="bottom-item">
              <div class="item-value">$1M</div>
              <div class="item-label">Sales </div>
            </div>
            <div class="bottom-line"></div>
            <div class="bottom-item">
              <div class="item-value">Nutrition</div>
              <div class="item-label">Industry</div>

            </div>
          </div>
          </div>

        </div>
        <div class="item animatedBorder">
          <div class="item-wrap">
            <div class="item-mid">
              <div class="item-logo">
                <img src="@/assets/company-logo.png" alt="" />
              </div>
              <div class="item-right">
                <div class="item-name">Test Company 1</div>
                <div class="item-location">CA USA</div>
              </div>

            </div>
            <div class="item-bottom">
              <div class="bottom-item">
                <div class="item-value">100-200</div>
                <div class="item-label">Size</div>

              </div>
              <div class="bottom-line"></div>
              <div class="bottom-item">
                <div class="item-value">$1M</div>
                <div class="item-label">Sales </div>
              </div>
              <div class="bottom-line"></div>
              <div class="bottom-item">
                <div class="item-value">Nutrition</div>
                <div class="item-label">Industry</div>

              </div>
            </div>
          </div>

        </div>
        <div class="item animatedBorder">
          <div class="item-wrap">
            <div class="item-mid">
              <div class="item-logo">
                <img src="@/assets/company-logo.png" alt="" />
              </div>
              <div class="item-right">
                <div class="item-name">Test Company 5555</div>
                <div class="item-location">CA USA</div>
              </div>

            </div>
            <div class="item-bottom">
              <div class="bottom-item">
                <div class="item-value">100-200</div>
                <div class="item-label">Size</div>

              </div>
              <div class="bottom-line"></div>
              <div class="bottom-item">
                <div class="item-value">$2M</div>
                <div class="item-label">Sales </div>
              </div>
              <div class="bottom-line"></div>
              <div class="bottom-item">
                <div class="item-value">Food</div>
                <div class="item-label">Industry</div>

              </div>
            </div>
          </div>

        </div>
        <div class="item animatedBorder">
          <div class="item-wrap">
            <div class="item-mid">
              <div class="item-logo">
                <img src="@/assets/new-company/company-logo.png" alt="" />
              </div>
              <div class="item-right">
                <div class="item-name">Test Company 1</div>
                <div class="item-location">CA USA</div>
              </div>

            </div>
            <div class="item-bottom">
              <div class="bottom-item">
                <div class="item-value">100-200</div>
                <div class="item-label">Size</div>

              </div>
              <div class="bottom-line"></div>
              <div class="bottom-item">
                <div class="item-value">$1M</div>
                <div class="item-label">Sales </div>
              </div>
              <div class="bottom-line"></div>
              <div class="bottom-item">
                <div class="item-value">Nutrition</div>
                <div class="item-label">Industry</div>

              </div>
            </div>
          </div>

        </div>
        <div class="item animatedBorder">
          <div class="item-wrap">
            <div class="item-mid">
              <div class="item-logo">
                <img src="@/assets/company-logo.png" alt="" />
              </div>
              <div class="item-right">
                <div class="item-name">Test Company 1</div>
                <div class="item-location">CA USA</div>
              </div>

            </div>
            <div class="item-bottom">
              <div class="bottom-item">
                <div class="item-value">100-200</div>
                <div class="item-label">Size</div>

              </div>
              <div class="bottom-line"></div>
              <div class="bottom-item">
                <div class="item-value">$1M</div>
                <div class="item-label">Sales </div>
              </div>
              <div class="bottom-line"></div>
              <div class="bottom-item">
                <div class="item-value">Nutrition</div>
                <div class="item-label">Industry</div>

              </div>
            </div>
          </div>

        </div>
        <div class="item animatedBorder">
          <div class="item-wrap">
            <div class="item-mid">
              <div class="item-logo">
                <img src="@/assets/company-logo.png" alt="" />
              </div>
              <div class="item-right">
                <div class="item-name">Test Company 1</div>
                <div class="item-location">CA USA</div>
              </div>

            </div>
            <div class="item-bottom">
              <div class="bottom-item">
                <div class="item-value">100-200</div>
                <div class="item-label">Size</div>

              </div>
              <div class="bottom-line"></div>
              <div class="bottom-item">
                <div class="item-value">$1M</div>
                <div class="item-label">Sales </div>
              </div>
              <div class="bottom-line"></div>
              <div class="bottom-item">
                <div class="item-value">Nutrition</div>
                <div class="item-label">Industry</div>

              </div>
            </div>
          </div>

        </div>
        <div class="item animatedBorder">
          <div class="item-wrap">
            <div class="item-mid">
              <div class="item-logo">
                <img src="@/assets/company-logo.png" alt="" />
              </div>
              <div class="item-right">
                <div class="item-name">Test Company 1</div>
                <div class="item-location">CA USA</div>
              </div>

            </div>
            <div class="item-bottom">
              <div class="bottom-item">
                <div class="item-value">100-200</div>
                <div class="item-label">Size</div>

              </div>
              <div class="bottom-line"></div>
              <div class="bottom-item">
                <div class="item-value">$1M</div>
                <div class="item-label">Sales </div>
              </div>
              <div class="bottom-line"></div>
              <div class="bottom-item">
                <div class="item-value">Nutrition</div>
                <div class="item-label">Industry</div>

              </div>
            </div>
          </div>

        </div>
      </div>


    </div>

  </div>


</template>
<script>
  export default {
    computed: {},
    components: {

    },
    data() {
      return {
        mobileWidth: window.innerWidth < 800
      };
    },
    created() {},
    mounted() {},
    methods: {
      createStorefront(){

      },
      companyDetail(){
        this.$router.push(`/slug/test`);
      },
    },
    filters: {}
  };
</script>
<style lang="less" scoped>
.sc-width {
  margin: 78px auto 0 auto;
  padding-bottom: 30px;
}

.d-flex {
  display: flex;
}

.banner {

  background: #120D21;
  padding-top: 30px;
  padding-bottom: 20px;

  .banner-content {
    display: flex;
    justify-content: space-between;
    width: 1600px;
    height: 400px;

  }

  .banner-left {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      width: 600px;
      font-size: 40px;
      font-weight: 700;
      color: #fff;

      .color {
        background: linear-gradient(to right, #5DBAFF, #FFA5CB, #DB537F, #615AD3);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
        -o-text-fill-color: transparent;

      }


    }

    .sub-title {
      color: #C7C7C7;
      font-size: 18px;
      margin-top: 20px;
      font-weight: 500;
    }

    .banner-btn {
      margin-top: 30px;
      width: 320px;
      height: 60px;
      background: linear-gradient(#1a1a1a, #1a1a1a) padding-box,
        linear-gradient(to left, #ffa5cb, #33ccff) border-box;
      border-radius: 6px;
      color: #fff;
      font-size: 17px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 35px;
      cursor: pointer;
      border: 3px solid transparent;
    }


  }

  .banner-right {
    display: flex;
    // flex-direction: column;
    justify-content: center;
    margin-right: 50px;
    align-items: center;

    video {
      width: 390px;
      height: 390px;

    }

    .hide-video {
      width: 20px;
      height: 400px;
      background: #120D21;
      position: relative;
      right: 17px;
    }

  }
}

.companies {
  .companies-content:after{
    content: '';
    width: 380px;
  }
  .companies-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 15px !important;

    .item {
      width: 380px;
      padding: 20px;
      /*border: 1px solid #b9b9b9;*/
      /*border-radius: 10px;*/
      cursor: pointer;
      margin: 12px 0;

      .item-top {
        display: flex;
        justify-content: space-between;

        .top-item {
          width: 85px;
          height: 60px;
          border-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            width: 30px;
          }

        }

        .top-item-1 {
          background-image: linear-gradient(90deg, #9d4ee5, #3f55dd);
        }

        .top-item-2 {
          background-image: linear-gradient(90deg, #e94231, #f1cc00);
        }

        .top-item-3 {
          background-image: linear-gradient(90deg, #0c5bfa, #f66e7c);
        }

        .top-item-4 {
          background-image: linear-gradient(90deg, #d0295b, #ed7b28);
        }

      }

      .item-mid {
        /*position: relative;*/
        display: flex;
        /*bottom: 25px;*/
        padding: 5px 0 25px 0;
      }

      .item-logo {
        display: flex;
        justify-content: center;

        img {
          width: 70px;
          height: 70px;
          border-radius: 50%;
        }

      }
      .item-right{
        margin-left: 15px;
      }

      .item-name {
        display: flex;
        justify-content: center;
        color: #2F2F2F;
        font-size: 18px;
        font-weight: 700;
        margin-top: 10px;

      }

      .item-location {
        /*display: flex;*/
        /*justify-content: center;*/
        color: #949494;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 7px;
        margin-top: 4px;
      }

      .item-bottom {
        display: flex;
        justify-content: space-between;
        /*position: relative;*/
        padding-left:10px;
        padding-right:10px;
        /*bottom: 15px;*/
        .bottom-item {
          .item-value {
            color: #2F2F2F;
            font-size: 16x;
            font-weight: 600;
            display: flex;
            justify-content: center;
          }

          .item-label {
            color: #949494;
            font-size: 15px;
            display: flex;
            justify-content: center;
          }
        }

        .bottom-line {
          background: #D9D9D9;
          width: 1px;
          height: 40px;
        }
      }
    }
  }
}

.search{
  display: flex;
  justify-content: space-between;
  margin: 20px auto 0 auto;
  padding-bottom: 0;
  .left-search{
    display: flex;
    justify-self: start;
  }
  .right-search{
    margin-right: 40px;
    select{
      height: 35px;
      padding: 0 3px;
      border-radius: 5px;
      outline: none;
      border: 1px solid #eee;
      font-size: 17px;
      padding: 3px 8px;

    }
  }
  .industry{
    margin-right: 20px;
    span{
      font-size: 17px;
      font-weight: 500;
      margin-right: 8px;
    }
    select{
      height: 35px;
      padding: 0 3px;
      border-radius: 5px;
      outline: none;
      border: 1px solid #eee;
      font-size: 17px;

    }
  }
  .name{
    margin-right: 20px;
    span{
      font-size: 17px;
      font-weight: 500;
      margin-right: 8px;
    }
    input{
      height: 35px;
      padding: 0 8px;
      border-radius: 5px;
      outline: none;
      border: 1px solid #eee;
      font-size: 17px;

    }
  }
  .date{
    span{
      font-size: 17px;
      font-weight: 500;
      margin-right: 8px;
    }
    input{
      height: 35px;
      padding: 0 3px;
      border-radius: 5px;
      outline: none;
      border:1px solid #eee;
      font-size: 17px;
    }
    .start-date{
      margin-right: 4px;
    }
  }
  .btn{
    background: #3E1DCE;
    color: #fff;
    width: 80px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border-radius: 5px;
    margin-left: 15px;
    cursor: pointer;
    &:hover{
      opacity: 0.8;
    }
  }
  .btn-batch{
    border: 1px solid #3E1DCE;
    color: #3E1DCE;
    width: 120px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border-radius: 5px;
    margin-left: 15px;
    cursor: pointer;
    &:hover{
      opacity: 0.8;
    }
  }
}

.animatedBorder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /*width: 300px;*/
  height: 190px;
  border-radius: 10px;
  border-color: hsl(240 10% 3.9% / .1);
}
.animatedBorder:before {
  content: "";
  position: absolute;
  width: calc(100% + 300px);
  height: calc(100% + 300px);
  opacity: .3;
}
.animatedBorder:before {
  background-image: conic-gradient(transparent, transparent, transparent, #d400d4, transparent);
  rotate: 45deg;
}
.animatedBorder:after {
  background-image: conic-gradient(transparent, transparent, transparent, #0cf, transparent);
  rotate: 225deg;
}
.animatedBorder:after, .animatedBorder:before {
  content: "";
  position: absolute;
  width: calc(100% + 300px);
  height: calc(100% + 300px);
  opacity: .3;
}

.animatedBorder .item-wrap {
  z-index: 5;
  border-radius: 12px;
  background-color: #fff;
  position: absolute;
  inset: 2px;
  padding: 20px 15px;
}
.animatedBorder:hover:after, .animatedBorder:hover:before {
  opacity: 1;
  animation: animate 3s linear infinite;
}
@keyframes animate {
  0% {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(1turn)
  }
}
</style>
